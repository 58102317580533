<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah Dokter Baru</strong>
        </h5>
        <a-form :form="form"  @submit="handleSubmit">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nama Lengkap">
            <a-input
              :disabled="submitting"
              placeholder="Nama Lengkap"
              v-decorator="['fullname', {rules: [{ required: true, message: 'Nama lengkap harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nomor KTP">
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber', { rules: [{required: true, len: 16, message: 'Nomor KTP harus 16 digit!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="E-mail">
            <a-input
              :disabled="submitting"
              placeholder="E-mail"
              v-decorator="['email', {rules: [{ required: true, message: 'E-mail harus diisi!' }]}]"
            />
          </a-form-item>
           <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Verification Code">
            <a-input
              :disabled="submitting"
              placeholder="Verification Code"
              v-decorator="['verificationCode', {rules: [{ required: true, message: 'Verification Code harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Rumah Sakit">
            <a-input
              :disabled="submitting"
              placeholder="Rumah Sakit"
              v-decorator="['hospital', {rules: [{ required: true, message: 'Nama rumah sakit harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kota">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'city',
                { rules: [{ required: true, message: 'Kota harus diisi!' }] },
              ]"
              placeholder="Kota"
            >
              <a-select-option v-for="city in cityList" :key="city.id" :value="city.name">
                  {{city.name}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Program">
            <a-select
              mode="multiple"
              style="width: 100%"
              placeholder="Program Type"
              v-decorator="[
                'programTypes',
                { rules: [{ required: true, message: 'Program Types harus diisi!' }] },
              ]"
            >
              <a-select-option value="TAGRISSO">
                TAGRISSO
              </a-select-option>
              <a-select-option value="FASLODEX">
                FASLODEX
              </a-select-option>
              <a-select-option value="LYNPARZA">
                LYNPARZA
              </a-select-option>
              <a-select-option value="IMFINZI">
                IMFINZI
              </a-select-option>
              <a-select-option value="FASENRA">
                FASENRA
              </a-select-option>
              <a-select-option value="CALQUENCE">
                CALQUENCE
              </a-select-option>
            </a-select>
          </a-form-item>
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import router from '@/router'
import { getAll } from '@/services/axios/api/city'
import { create } from '@/services/axios/api/dokter'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      submitting: false,
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await create(values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/dokter')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
  },
}
</script>
